<template>
  <div>
    <!-- Navbar -->
    <div class="row" style="margin-top: 50px">
      <div class="col-sm-6 col-md-3 col-6">
        <!-- Sidebar -->
        <nav
          id="sidebarMenu"
          class="collapse d-lg-block sidebar collapse bg-white pt-5"
        >
          <div class="position-sticky">
            <KTBrand></KTBrand>
            <b-button
              variant="link"
              class="my-1 text-center"
              @click="goToSimulationLink"
              block
              >{{ $t("virtual_pos_api_doc.simulation_link") }}</b-button
            >
            <div class="list-group list-group-flush mx-3 mt-4">
              <button
                v-for="(tab, tabIdx) in miracleCustomerSideTabs"
                :key="`miracleCustomerSideTab-${tabIdx}`"
                @click="tab.onClick"
                class="list-group-item list-group-item-action py-2 ripple"
                aria-current="true"
              >
                <i :class="tab.icon"></i>
                <span class="ml-2">{{ tab.label }}</span>
              </button>
            </div>
          </div>
        </nav>
        <!-- Sidebar -->
      </div>
      <div class="col-sm-6 col-md-9 col-6">
        <div
          class="d-flex py-1 px-1 flex-row mb-3 fixed-top bg-white rounded offset-sm-6 offset-md-3 offset-6"
        >
          <div class="flex-column d-flex">
            <label>{{ $t("virtual_pos_api_doc.customer") }}</label>
            <b-dropdown
              size="sm"
              :text="getCustomerDropdownText()"
              variant="outline-primary"
            >
              <b-dropdown-item-button
                @click="isMiracleCustomer = true"
                :key="'MIRACLE_CUSTOMER'"
              >
                <span class="d-inline-flex align-items-center">
                  <b-icon
                    v-if="isMiracleCustomer"
                    icon="check"
                    font-scale="1.5"
                    class="mr-1"
                    variant="success"
                  ></b-icon>
                  {{
                    $t(
                      "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.MIRACLE_CUSTOMER"
                    )
                  }}
                </span>
              </b-dropdown-item-button>
              <b-dropdown-item-button
                @click="isMiracleCustomer = false"
                :key="'NOT_MIRACLE_CUSTOMER'"
              >
                <span class="d-inline-flex align-items-center">
                  <b-icon
                    v-if="!isMiracleCustomer"
                    icon="check"
                    font-scale="1.5"
                    class="mr-1"
                    variant="success"
                  ></b-icon>
                  {{
                    $t(
                      "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.NOT_MIRACLE_CUSTOMER"
                    )
                  }}
                </span>
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
          <div class="flex-column d-flex ml-2">
            <label>Environment</label>
            <b-dropdown
              size="sm"
              :text="getEnvDropdownText()"
              variant="outline-primary"
            >
              <b-dropdown-item-button @click="onChangeEnv(true)" :key="'dev'">
                <span class="d-inline-flex align-items-center">
                  <b-icon
                    v-if="isDev"
                    icon="check"
                    font-scale="1.5"
                    class="mr-1"
                    variant="success"
                  ></b-icon>
                  Development
                </span>
              </b-dropdown-item-button>
              <b-dropdown-item-button @click="onChangeEnv(false)" :key="'prod'">
                <span class="d-inline-flex align-items-center">
                  <b-icon
                    v-if="!isDev"
                    icon="check"
                    font-scale="1.5"
                    class="mr-1"
                    variant="success"
                  ></b-icon>
                  Production
                </span>
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
        <div class="bg-secondary p-10" id="intro">
          <h1>{{ $t("virtual_pos_api_doc.intro") }}</h1>
          <p
            v-html="$t('virtual_pos_api_doc.intro_desc')"
            v-if="isMiracleCustomer"
          ></p>
          <p
            v-html="$t('virtual_pos_api_doc.intro_non_miracle_customer_desc')"
            v-else
          ></p>
          <div class="mt-20">
            <h3>{{ $t("virtual_pos_api_doc.example") }}:</h3>
            <div class="row">
              <img :src="res1" alt="res1" class="w-100 h-75 mb-3" />
            </div>
            <div class="row">
              <img :src="res2" alt="res2" class="w-100 h-75 mb-3" />
            </div>
            <div class="row">
              <img :src="res3" alt="res3" class="w-100 h-75 mb-3" />
            </div>
            <div class="row" v-if="isMiracleCustomer">
              <img :src="res4" alt="res4" class="w-100 h-75 mb-3" />
            </div>
            <div class="row" v-if="!isMiracleCustomer">
              <img :src="res5" alt="res5" class="w-100 h-75 mb-3" />
            </div>
            <div class="row" v-if="!isMiracleCustomer">
              <img :src="res6" alt="res6" class="w-100 h-75" />
            </div>
          </div>
        </div>
        <div class="row p-5">
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="auth">
            <div class="col-sm-12"><h1>Authentication</h1></div>
            <div class="row">
              <div class="col-12">
                <h3>
                  <small
                    >POST-- {{ $t("virtual_pos_api_doc.auth_desc") }}:
                  </small>
                  {{ `${mpayBaseUrl}authentication_token` }}
                </h3>
              </div>
              <pre class="col-12 code text-white">
                Request Body
                {{
                  `
                  {
                    "email": "test@test.com",
                    "password": "000000"
                  }
                  `
                }}
              </pre>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9...."
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="createOrder">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.create_order") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >POST-- {{ $t("virtual_pos_api_doc.create_order_desc") }}:
                  </small>
                  {{ `${mpayApiBaseUrl}company-orders` }}
                </h3>
              </div>
              <pre class="col-12 code text-white">
                Request Body
                {{
                  `
                  {
                    "customerMail":"",
                    "customerName":"",
                    "customerPhone":"",
                    "price":"22"
                  }
                  `
                }}
              </pre>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    amount: null,
                    createdAt: "2022-07-12T11:34:27+00:00"
                    customerMail: ""
                    customerName: "",
                    customerPhone: "",
                    fee: "5.00",
                    feeIncluded: false,
                    id: 1160,
                    merchantId: 3,
                    nexusAmount: null,
                    orderNumber: "20220712C740386C5FB5",
                    orderStatus: "pending",
                    paymentUnit: null,
                    paymentUnitName: null,
                    platform: null,
                    price: "22.00",
                    selectedCryptoCurrencyBuying: null,
                    selectedCurrencyBuying: null,
                    status: 0,
                    updatedAt:
                    "2022-07-12T11:34:27+00:00"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="getCompanyOrder">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.get_company_order") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >GET--
                    {{ $t("virtual_pos_api_doc.get_company_order_desc") }}:
                  </small>
                  {{ `${mpayApiBaseUrl}company-orders/{orderId}` }}
                </h3>
              </div>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    "id": 2141,
                    "selectedCurrencyId": 6,
                    "selectedCurrencyCode": "ETH",
                    "price": "22.00",
                    "fee": "3.00",
                    "feeIncluded": false,
                    "customerName": "",
                    "customerMail": "",
                    "customerPhone": "",
                    "status": 0,
                    "orderNumber": "202411272C5D3FBFA96D",
                    "amount": 0.0069088104,
                    "platform": "Other",
                    "nexusAmount": null,
                    "paymentUnit": {
                        "id": 4,
                        "name": "EUR"
                    },
                    "selectedCurrencyBuying": null,
                    "selectedCryptoCurrencyBuying": null,
                    "nexusAmountConvertCurrency": null,
                    "paymentState": "\/api\/payment-states\/1",
                    "feeAmount": 0.66,
                    "orderTotal": 22.66,
                    "commissionConvertCurrency": 0.0002012275,
                    "sourceAddress": null,
                    "txHash": null,
                    "device": "\/api\/devices\/3",
                    "fiat": true,
                    "commissionStatus": null,
                    "diffAmount": null,
                    "diffPrice": null,
                    "createdAt": "2024-11-27T11:28:31+00:00",
                    "updatedAt": "2024-11-27T11:29:55+00:00",
                    "orderStatus": "pending",
                    "branchTitle": "Hollanda Branch Test",
                    "merchantId": 387,
                    "merchantName": "Hollanda Merch test",
                    "paymentUnitName": "EUR",
                    "paymentStateId": 1,
                    "paymentStateName": "onstandby",
                    "responsibleAdminId": 1,
                    "deviceShort": "pnl",
                    "merchantFiat": true
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="convert">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.convert") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >POST-- {{ $t("virtual_pos_api_doc.convert_desc") }}:
                  </small>
                  {{ `${ofinansApiBaseUrl}miracle-pay/convert` }}
                </h3>
              </div>
              <pre class="col-12 code text-white">
                Request Body
                {{
                  `
                  {
                    "currency":"usd",
                    "crypto":"eth",
                    "amount":100
                  }
                  `
                }}
              </pre>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    "success": "ok",
                    "crypto_amount": 0.01435609,
                    "currency": "USD",
                    "crypto_code": "ETH",
                    "message": "your transaction was successfully"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="payment_stage">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.payment_stage") }}</h1>
            </div>
            <div>
              <div class="col-12 text-break">
                <h3>
                  <small
                    >{{ isMiracleCustomer ? "POST" : "GET" }}--
                    {{ $t("virtual_pos_api_doc.payment_stage_desc") }}:</small
                  >
                  {{
                    isMiracleCustomer
                      ? `${mpayApiBaseUrl}company-orders/{orderId}/transactions/qr-sale`
                      : `${mpayApiBaseUrl}nexus/create_payment/{crypto}/{orderId}/{paymentUnit}`
                  }}
                  <template v-if="!isMiracleCustomer">
                    <p class="mt-2">crypto: <strong>btc,eth</strong>,...</p>
                    <p>paymentUnit: <strong>usdt,try,eur</strong>,...</p>
                  </template>
                </h3>
                <p v-html="$t('virtual_pos_api_doc.payment_stage_desc_2')"></p>
              </div>
              <pre class="col-12 text-white code" v-if="isMiracleCustomer">
                Request Body
                {{
                  `
                  {
                    "hash":"a35ed7fc45142b68b2d46a4f39916...",
                    "selectedCurrencyCode":"ETH",
                    "selectedCurrencyId":2,
                    "selectedPaymentName":"usd"
                  }
                  `
                }}
              </pre>
              <pre class="col-12 text-white code mt-2" v-if="isMiracleCustomer">
                Response
                {{
                  `
                    {
                        "status": 200,
                        "content": {
                            "success": "ok",
                            "transaction_id": 293,
                            "cyrpto_amount": 0.00066117,
                            "currency": "USD",
                            "cyrpto": "BTC",
                            "message": "your transaction was successfully",
                            "user": {
                                "id": 123456,
                                "affiliate_id": null,
                                "name": "User",
                                ..
                            }
                        }
                    }
                    `
                }}
              </pre>
              <pre class="col-12 text-white code mt-2" v-else>
                Response
                {{
                  `
                  {
                      "address": "0x4F1EE081cf92699b011eA7E0583395671f46e4f8",
                      "walletNetwork": {
                        "id": 136,
                        "name": "Ethereum",
                        "code": "ETH",
                        "currency_id": 6,
                        "asset_id": 12,
                        "created_at": "2023-05-07T14:32:09.000000Z",
                        "updated_at": "2023-05-07T14:32:09.000000Z"
                      }
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="currency">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.crypto_currencies") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >GET--
                    {{
                      $t("virtual_pos_api_doc.crypto_currencies_desc")
                    }}:</small
                  >
                  {{ `${mpayApiBaseUrl}companies/{id}/company-wallets` }}
                </h3>
                <p
                  v-html="
                    $t('virtual_pos_api_doc.crypto_currencies_desc_2', {
                      uri: mpayApiBaseUrl
                    })
                  "
                ></p>
              </div>
              <pre class="text-white col-12 code">
                Response
                {{
                  `
                    [
                      {
                        "id": 1111,
                        "balance": "0.00",
                        "wallet": {
                            "currencyCode": "BTC",
                            "currencyName": "Bitcoin",
                            "currencyIcon": "cdnpath/btc.png",
                            "createdAt": "2024-01-21T09:07:47+00:00",
                            "updatedAt": "2024-01-21T09:07:47+00:00"
                        },
                        "createdAt": "2024-02-12T13:36:56+00:00",
                        "updatedAt": "2024-02-12T13:36:56+00:00"
                      },
                      {
                        "id": 1112,
                        "balance": "0.00",
                        "wallet": {
                            "currencyCode": "ETH",
                            "currencyName": "Ethereum",
                            "currencyIcon": "cdnpath/eth.png",
                            "createdAt": "2024-01-21T09:07:47+00:00",
                            "updatedAt": "2024-01-21T09:07:47+00:00"
                        },
                        "createdAt": "2024-02-12T13:36:56+00:00",
                        "updatedAt": "2024-02-12T13:36:56+00:00"
                      },
                      ...
                    ]
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12 mt-40 bg-secondary p-10" id="getMe">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.getMe") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >GET-- {{ $t("virtual_pos_api_doc.getMe_desc") }}:</small
                  >
                  {{ `${mpayApiBaseUrl}me` }}
                </h3>
              </div>
              <pre class="text-white code col-12">
                Response
                {{
                  `
                  {
                    "parent": {
                        "id": 5,
                        "title": "Test Company Ltd.",
                        "email": "other@branch.com",
                        "taxCity": "adsdasd",
                        "taxAdministration": "dasdasd",
                        "taxNumber": "32123",
                        "hasQuickSale": false,
                        "role": {
                            "roleRank": "ROLE_BRANCH",
                            "canSubDeal": false,
                            "hasOfinansAccount": false,
                            "hasWallets": false,
                            "canSellQrCode": true
                        },
                        "isActive": true,
                        "paymentUnit": {
                            "id": 1,
                            "name": "usd"
                        },
                        "commission": 5,
                        "deletedAt": null,
                        "createdAt": "2022-01-03T10:08:50+03:00",
                        "updatedAt": "2022-07-06T20:55:19+03:00",
                        "companyRank": "BRANCH",
                        "ordersTotals": "108,422.72"
                    },
                    "posNumber": null,
                    "createdAt": "2022-01-03T10:08:50+03:00",
                    "updatedAt": "2022-09-15T15:15:58+03:00",
                    "id": 7,
                    "roles": [
                        "ROLE_BRANCH"
                    ],
                    "email": "other@branch.com",
                    "phone": "+905",
                    "firstName": "Other",
                    "lastName": "Admin",
                    "isActive": true,
                    "userRole": {
                        "roleRank": "ROLE_BRANCH",
                        "canSubDeal": false,
                        "hasOfinansAccount": false,
                        "hasWallets": false,
                        "canSellQrCode": true
                    },
                    "googleTfaEnabled": false,
                    "merchantId": 3,
                    "fullName": "Other Admin"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12 mt-40 bg-secondary p-10" id="deleteAddress">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.delete_wallet_address") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >POST--
                    {{ $t("virtual_pos_api_doc.delete_wallet_address_desc") }}:
                  </small>
                  {{ `${mpayApiBaseUrl}delete-wallet-address` }}
                </h3>
              </div>
              <pre class="col-12 code text-white">
                Request Body
                {{
                  `
                  {
                    "address": "0x4F1............",
                  }
                  `
                }}
              </pre>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    "success": "ok",
                    "message": "success"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12 mt-40 bg-secondary p-10" id="iframeApi">
            <div class="col-sm-12">
              <h1>IFrame API / SDK</h1>
            </div>
            <div class="col-12 d-flex flex-column">
              <p
                class="text-secondary font-weight-light"
                style="font-size: large;"
              >
                Before you begin you must obtain authentication_token from
                <span
                  class="text-primary cursor-pointer font-weight-bold"
                  @click="scrollToView('#auth')"
                  >Authentication</span
                >
                section.
              </p>
              <div class="my-3 iframe-images">
                <div>
                  <img
                    :src="iframeCreateOrderImg"
                    alt=""
                    style="max-width: 250px; height: 250px; object-fit: cover;"
                    class="cursor-pointer"
                    @click="onShowImgModal(iframeCreateOrderImg)"
                  />
                </div>
                <div>
                  <img
                    :src="iframeTransactionPending"
                    alt=""
                    style="max-width: 250px; height: 250px; object-fit: cover;"
                    class="cursor-pointer"
                    @click="onShowImgModal(iframeTransactionPending)"
                  />
                </div>
                <div>
                  <img
                    :src="iframeTransactionSuccess"
                    alt=""
                    style="max-width: 250px; height: 250px; object-fit: cover;"
                    class="cursor-pointer"
                    @click="onShowImgModal(iframeTransactionSuccess)"
                  />
                </div>
                <div>
                  <img
                    :src="iframeMissingPayment"
                    alt=""
                    style="max-width: 250px; height: 250px; object-fit: cover;"
                    class="cursor-pointer"
                    @click="onShowImgModal(iframeMissingPayment)"
                  />
                </div>
                <div>
                  <img
                    :src="iframeTransactionError"
                    alt=""
                    style="max-width: 250px; height: 250px; object-fit: cover;"
                    class="cursor-pointer"
                    @click="onShowImgModal(iframeTransactionError)"
                  />
                </div>
              </div>
              <span
                class="col-12 code text-white text-left rounded position-relative"
                style="font-size: small; padding: 20px 15px;"
              >
                {{ iframeUrl() }}
                <span
                  class="p-3 cursor-pointer position-absolute top-0 right-0"
                  @click="copyText(iframeUrl())"
                >
                  <i class="fa fa-clipboard"></i>
                </span>
              </span>
              <div class="mt-3">
                <p>
                  You can listen for
                  <span class="font-weight-bolder text-dark">onSuccess</span>
                  event when <b class="text-dark">payment completed</b> or when
                  <b class="text-dark">missing order payment</b> has been made
                  like below:
                </p>
                <pre class="code rounded p-5" style="padding: 0;">
document.addEventListener("DOMContentLoaded", () => {
  window.addEventListener('message', function(event) {
    if(event.data?.event && event.data.event ==='onSuccess') { 
      // <span class="text-primary cursor-pointer font-weight-bold" @click="scrollToView('#getCompanyOrder')">event.data.data: order</span>
      console.log(event.data.data);

      // your code
    } 
  });
});
</pre>

                <p>
                  And you can listen for
                  <span class="font-weight-bolder text-dark"
                    >onSuccessRedirect</span
                  >
                  event when clicked to
                  <span class="font-weight-bolder text-dark">OK button</span>
                  like below:
                </p>
                <pre class="code rounded p-5" style="padding: 0;">
document.addEventListener("DOMContentLoaded", () => {
  window.addEventListener('message', function(event) {
    if(event.data?.event && event.data.event ==='onSuccessRedirect') {
      // your code
    } 
  });
});
</pre
                >

                <p>
                  And you can listen for
                  <span class="font-weight-bolder text-dark"
                    >onCopyWalletAddress</span
                  >
                  event when clicked to
                  <span class="font-weight-bolder text-dark">Copy button</span>
                  like below:
                </p>
                <pre class="code rounded p-5" style="padding: 0;">
document.addEventListener("DOMContentLoaded", () => {
  window.addEventListener('message', function(event) {
    if(event.data?.event && event.data.event ==='onCopyWalletAddress') { 
      console.log("copied wallet address: " + event.data.data);
      // your code
    } 
  });
});
                </pre>

                <p>
                  In case of
                  <span class="font-weight-bolder text-dark"
                    >insufficient payment</span
                  >
                  you can listen for
                  <span class="font-weight-bolder text-dark"
                    >onInsufficientPayment</span
                  >
                  event like below:
                </p>
                <pre class="code rounded p-5" style="padding: 0;">
document.addEventListener("DOMContentLoaded", () => {
  window.addEventListener('message', function(event) {
    if(event.data?.event && event.data.event ==='onInsufficientPayment') {
      // <span class="text-primary cursor-pointer font-weight-bold" @click="scrollToView('#getCompanyOrder')">event.data.data: order</span>
      console.log(event.data.data);
      
      // your code
    } 
  });
});
                </pre>

                <p>
                  And you can listen for
                  <span class="font-weight-bolder text-dark"
                    >onClickInsufficientOK</span
                  >
                  event when clicked to
                  <span class="font-weight-bolder text-dark"
                    >OK button in case of insufficient payment state</span
                  >
                  like below:
                </p>
                <pre class="code rounded p-5" style="padding: 0;">
document.addEventListener("DOMContentLoaded", () => {
  window.addEventListener('message', function(event) {
    if(event.data?.event && event.data.event ==='onClickInsufficientOK') { 
      // your code
    } 
  });
});
                </pre>

                <p>
                  In case of
                  <span class="font-weight-bolder text-dark"
                    >transaction error</span
                  >
                  you can listen for
                  <span class="font-weight-bolder text-dark">onError</span>
                  event like below:
                </p>
                <pre class="code rounded p-5" style="padding: 0;">
document.addEventListener("DOMContentLoaded", () => {
  window.addEventListener('message', function(event) {
    if(event.data?.event && event.data.event ==='onError') {
      // <span class="text-primary cursor-pointer font-weight-bold" @click="scrollToView('#getCompanyOrder')">event.data.data: order</span>
      console.log(event.data.data);
      
      // your code
    } 
  });
});
                </pre>

                <p>
                  In case of the page is
                  <span class="font-weight-bolder text-dark"
                    >reloaded(refreshed)</span
                  >
                  where the iframe is implemented it must be a request to
                  <span
                    class="text-primary cursor-pointer font-weight-bold"
                    @click="scrollToView('#deleteAddress')"
                    >delete wallet address endpoint</span
                  >. <br />
                  Instructions for
                  <span class="font-weight-bolder text-dark"
                    >onGetNexusPaymentAddress</span
                  >
                  event listening and how the wallet address can be stored where
                  implemented the iframe after the user clicked to
                  <span class="font-weight-bolder text-dark"
                    >Pay with Miracle Pay</span
                  >
                  button and wallet address is appeared in the UI;
                </p>
                <pre class="code rounded p-5" style="padding: 0;">
// steps to consider in your side
document.addEventListener("DOMContentLoaded", async () => {
// 1. store wallet address in localStorage or sessionStorage
  window.addEventListener('message', function(event) {
    if(event.data?.event && event.data.event ==='onGetNexusPaymentAddress') { 
      window.localStorage.setItem('wallet_address_key', event.data.data)
    }
  });

// 2. send request to delete-wallet-address and remove from storage
  const walletAddress = window.localStorage.getItem("wallet_address_key")
  if(walletAddress) {
    // post request to <span class="text-primary cursor-pointer font-weight-bold" @click="scrollToView('#deleteAddress')">delete wallet address endpoint</span>
    try {
      await window.axios.post({{ `${mpayApiBaseUrl}delete-wallet-address` }}, {
          wallet_address: mpWalletAddress
      }, { 
        headers: {
          Authorization: `Bearer <span class="text-danger font-weight-bold">access_token</span>`
        }
      });
      window.localStorage.removeItem('wallet_address_key');
    } catch (error) {
      console.error(error);
    }
  }
})
                </pre>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Field</th>
                      <th scope="col">Required</th>
                      <th scope="col">Value</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>token</td>
                      <td>
                        <b-icon
                          icon="check"
                          font-scale="2"
                          class="text-success"
                        ></b-icon>
                      </td>
                      <td>
                        String
                      </td>
                      <td>
                        User access token from
                        <span
                          class="cursor-pointer font-weight-bolder"
                          @click="scrollToView('#auth')"
                          >{{ `${mpayBaseUrl}authentication_token` }}</span
                        >
                        User must be in
                        <span class="font-weight-bolder">BRANCH</span> role
                      </td>
                    </tr>
                    <tr>
                      <td>order_price</td>
                      <td>
                        <b-icon
                          icon="check"
                          font-scale="2"
                          class="text-success"
                        ></b-icon>
                      </td>
                      <td>Number</td>
                      <td>
                        Order price must be numeric(can be decimal, max 2
                        digits)
                      </td>
                    </tr>
                    <tr>
                      <td>commission_payer</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>customer | branch</td>
                      <td>
                        Who will pay the commission in the Miraclepay side
                      </td>
                    </tr>
                    <tr>
                      <td>rt_url</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>String</td>
                      <td>
                        Redirect url after payment completed and pressed to OK
                        button in transaction success screen. If it is not
                        provided onSuccessRedirect event will be triggered. This
                        event can be listened where it's implemented the iframe
                        like below;
                        <pre class="code rounded" style="padding: 0;">

  window.addEventListener('message', function(event) {
    if(event.data?.event && event.data.event ==='onSuccessRedirect') { 
      console.log("order: " + event.data.data);
    } 
  });

</pre
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>theme</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>light | dark</td>
                      <td>
                        light as default
                      </td>
                    </tr>
                    <tr>
                      <td>locale</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>en | tr</td>
                      <td>
                        en as default
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>payment_unit</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>USD | GBP | TRY | EUR</td>
                      <td>
                        Not case sensitive
                      </td>
                    </tr> -->
                    <tr>
                      <td>crypto</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>String</td>
                      <td>Not case sensitive(Default value is <b>USDT</b>)</td>
                    </tr>
                    <tr>
                      <td>customer_email</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>String(email)</td>
                      <td>
                        --
                      </td>
                    </tr>
                    <tr>
                      <td>customer_name</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>String</td>
                      <td>
                        --
                      </td>
                    </tr>
                    <tr>
                      <td>customer_phone</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>String</td>
                      <td>
                        --
                      </td>
                    </tr>
                    <tr>
                      <td>auto_submit_pay_btn</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>true|false</td>
                      <td>
                        Default is <b>false</b>. Might be string or boolean.
                        When "<b>payment_unit</b>" and "<b>crypto</b>" params
                        params both are defined then
                        <b>"Pay with MiraclePay"</b> button will be triggered
                        automatically. Otherwise user should click/press to the
                        button manuelly.
                      </td>
                    </tr>
                    <tr>
                      <td>insufficient_payment_state_extra_info_text</td>
                      <td>
                        <b-icon
                          icon="x"
                          font-scale="2"
                          class="text-danger"
                        ></b-icon>
                      </td>
                      <td>String</td>
                      <td>
                        Default is <b>null</b>. If it is wanted to add extra
                        information like "reach out customer support" in case of
                        <b>insufficient payment state</b>.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="modal-image-zoomed"
      hide-footer
      size="xl"
      title=""
      @hide="selectedIframeImg = null"
    >
      <div>
        <!-- <img
          :src="selectedIframeImg"
          class="img-fluid"
          style="border-radius: 10px;"
          alt=""
        /> -->
        <inner-image-zoom
          v-if="selectedIframeImg"
          :src="selectedIframeImg"
          :zoomSrc="selectedIframeImg"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import KTBrand from "@/view/layout/brand/Brand.vue";
import { OVERRIDE_PAGE_LAYOUT_CONFIG } from "../../store/modules/config";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";

export default {
  components: {
    KTBrand,
    "inner-image-zoom": InnerImageZoom
  },
  computed: {
    res1() {
      return require("@/assets/images/doc-images/res-1.png");
    },
    res2() {
      return require("@/assets/images/doc-images/res-2.png");
    },
    res3() {
      return require("@/assets/images/doc-images/res-3.png");
    },
    res4() {
      return require("@/assets/images/doc-images/res-4.png");
    },
    res5() {
      return require("@/assets/images/doc-images/res-5.png");
    },
    res6() {
      return require("@/assets/images/doc-images/res-6.png");
    },
    iframeCreateOrderImg() {
      return require("@/assets/images/doc-images/pay-sdk-transaction-start.png");
    },
    iframeTransactionPending() {
      return require("@/assets/images/doc-images/pay-sdk-transaction-pending.png");
    },
    iframeTransactionSuccess() {
      return require("@/assets/images/doc-images/pay-sdk-transaction-success.png");
    },
    iframeMissingPayment() {
      return require("@/assets/images/doc-images/pay-sdk-missing-payment.png");
    },
    iframeTransactionError() {
      return require("@/assets/images/doc-images/pay-sdk-transaction-error.png");
    }
  },
  data() {
    return {
      isMiracleCustomer: true,
      miracleCustomerSideTabs: [
        {
          label: this.$t("virtual_pos_api_doc.intro"),
          onClick: () => {
            this.scrollToView("#intro");
          },
          icon: "fas fa-tachometer-alt fa-fw me-3"
        },
        {
          label: "Authentication",
          onClick: () => {
            this.scrollToView("#auth");
          },
          icon: "fas fa-chart-area fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.create_order"),
          onClick: () => {
            this.scrollToView("#createOrder");
          },
          icon: "fas fa-lock fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.get_company_order"),
          onClick: () => {
            this.scrollToView("#getCompanyOrder");
          },
          icon: "fas fa-info fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.convert"),
          onClick: () => {
            this.scrollToView("#convert");
          },
          icon: "fas fa-exchange-alt fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.payment_stage"),
          onClick: () => {
            this.scrollToView("#payment_stage");
          },
          icon: "fas fa-chart-line fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.crypto_currencies"),
          onClick: () => {
            this.scrollToView("#currency");
          },
          icon: "fas fa-coins fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.getMe"),
          onClick: () => {
            this.scrollToView("#getMe");
          },
          icon: "fas fa-user fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.delete_wallet_address"),
          onClick: () => {
            this.scrollToView("#deleteAddress");
          },
          icon: "fas fa-trash fa-fw me-3"
        },
        {
          label: "IFrame API / SDK",
          onClick: () => {
            this.scrollToView("#iframeApi");
          },
          icon: "fas fa-link fa-fw me-3"
        }
      ],
      isDev: false,
      mpayBaseUrl: "https://apidev.miraclepay.nl/",
      mpayApiBaseUrl: "https://apidev.miraclepay.nl/api/",
      ofinansApiBaseUrl: "https://apidev.miraclecash.nl/v2/",
      selectedIframeImg: null
    };
  },
  methods: {
    scrollToView(idOrClass) {
      document.querySelector(idOrClass).scrollIntoView({ behavior: "smooth" });
    },
    getCustomerDropdownText() {
      return this.isMiracleCustomer
        ? this.$t(
            "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.MIRACLE_CUSTOMER"
          )
        : this.$t(
            "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.NOT_MIRACLE_CUSTOMER"
          );
    },
    getEnvDropdownText() {
      return this.isDev ? "Development" : "Production";
    },
    onChangeEnv(isDev) {
      this.isDev = isDev;
      if (isDev) {
        this.mpayBaseUrl = "https://apidev.miraclepay.nl/";
        this.mpayApiBaseUrl = "https://apidev.miraclepay.nl/api/";
        this.ofinansApiBaseUrl = "https://apidev.miraclecash.nl/v2/";
        return;
      }
      this.mpayBaseUrl = "https://api.miraclepay.nl/";
      this.mpayApiBaseUrl = "https://api.miraclepay.nl/api/";
      this.ofinansApiBaseUrl = "https://api.miraclecash.nl/v2/";
    },
    goToSimulationLink() {
      window.open(process.env.VUE_APP_VIRTUAL_POS_SIMULATION_APP, "_blank");
    },
    async copyText(text) {
      await navigator.clipboard.writeText(text);
      this.$root.$bvToast.toast("Copied to clipboard.", {
        title: this.$t("COMMON.INFORM"),
        variant: "info",
        autoHideDelay: 5000
      });
    },
    onShowImgModal(imgSource) {
      this.selectedIframeImg = imgSource;
      this.$refs["modal-image-zoomed"].show();
    },
    onHideImgModal() {
      this.$refs["modal-image-zoomed"].hide();
    },
    getHost() {
      return this.isDev
        ? "https://paneldev.miraclepay.nl"
        : "https://panel.miraclepay.nl";
    },
    iframeUrl() {
      return `${this.getHost()}/pay-sdk?token=ey...&order_price=5&commission_payer=&rt_url=&theme=&locale=&customer_name=&customer_email=&customer_phone=`;
    }
  },
  mounted() {
    this.onChangeEnv(false);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Empty Page" }]);
    this.$store.dispatch(OVERRIDE_PAGE_LAYOUT_CONFIG, {
      aside: { self: { minimize: { toggle: false } } }
    });
  }
};
</script>
<style>
body {
  background-color: #fbfbfb;
}

@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0; /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.code {
  background-color: #3f4254;
  color: white;
  padding: 20px;
}

table {
  tr {
    th {
      text-transform: uppercase;
    }
    td {
      vertical-align: middle !important;
    }
  }
}

.iframe-images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  div {
    width: 200px;
    height: 150px;
    overflow: hidden;
    border-radius: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    transition: all 0.2s ease-in-out;
  }
  div:hover {
    transform: scale(1.1);
    position: relative;
  }
}
</style>
